#pageHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 99;
}
#pageContent {
  // margin-top: 108px;
  margin-top: 57px;
  min-height: calc(100vh - 500px);
}
#pageFooter {
  padding: 50px 0;
  display: flex;
  align-items: center;
  background-color: $tds-blue-300;
  color: $white;
  .footer-links {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    a {
      color: $tds-grey-300;
      font-size: 0.875rem;
      padding: 8px 3px;
      margin: 0px 15px;
      text-transform: uppercase;
      &:hover,
      &:focus {
        color: $white;
        text-decoration: none;
      }
    }
  }
  &.standard-layout {
    @include media-breakpoint-up(lg) {
      padding-left: 300px;
    }
  }
}

.copyright {
  font-size: 0.875rem;
  font-family: $font-family-sans-serif;
  color: $tds-grey-300;
  // text-align: center;
  border-top: 1px solid $tds-grey-300;
  padding-top: 2rem;
  margin-top: 2rem;
}
