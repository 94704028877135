#mainnav {
  background-color: $tds-grey-100 !important;
  max-width: none;
  .nav-link {
    border: none;
    color: $tds-grey-300;
    text-transform: uppercase;
    font-size: 0.875rem;
    padding: 8px 3px;
    margin: 0px 15px;
    box-shadow: inset 0px 0px 0px $secondary;
    &:hover,
    &:focus {
      color: $white;
      box-shadow: inset 0px -80px 0px $secondary;
    }
  }
  .active {
    color: $tds-blue-300;
    border-bottom: 2px solid $tds-orange-200;
    // pointer-events: none;
    &:before {
      display: none !important;
    }
  }
  #nav-the-standard {
    color: $white;
    padding: 10px 15px;
    font-weight: 600;
    background-color: $tds-green-200;
    box-shadow: inset 0px 0px 0px $tds-blue-300;
    &:hover {
      box-shadow: inset 0px -80px 0px $tds-blue-300;
    }
    &.active {
      background-color: $dark;
      color: $white;
      pointer-events: visible;
      // border-bottom: 2px solid transparent;
    }
    &:hover::before {
      border-bottom: 0px;
    }
  }
  .navbar-brand {
    box-shadow: inset 0px 0px 0px transparent;
    &:hover,
    &:focus {
      box-shadow: inset 0px -80px 0px transparent;
    }
  }
}

#primarynav {
  .nav-link {
    box-shadow: inset 0px 0px 0px transparent;
    display: inline-block;
    position: relative;
    display: inline-block;
    text-decoration: none;
    position: relative;
    transition: all 0.5s;
    height: 100%;
    z-index: 1;
    &:hover {
      box-shadow: inset 0px 0px 0px transparent;
      color: $tds-blue-300;
    }
    &:before {
      content: " ";
      display: block;
      position: absolute;
      bottom: 0px;
      width: 0%;
      left: 50%;
      margin: 0 auto;
      transition: all 0.5s;
      z-index: -1;
      border-bottom: solid 2px $secondary;
    }
    &:before.active {
      width: 100%;
    }
    &:hover::before {
      border-bottom: solid 2px $secondary;
      width: 100%;
      left: 0%;
    }
  }
}

#navbar-toggler-text {
  background-color: $tds-green-200;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-family: $font-family-sans-serif;
  font-weight: 600;
  padding: 8px 15px;
  box-shadow: inset 0px 0px 0px $tds-orange-200;
  transition: 0.5s;
  &:hover,
  &:focus {
    color: $white;
    text-decoration: none;
    box-shadow: inset 0px -80px 0px $tds-orange-100;
  }
}

#tds-nav-lockup {
  width: 200px;
  height: 30px;
}

.nav-icon-wrapper {
  box-shadow: inset 0px 0px 0px transparent !important;
  &:hover,
  &:focus {
    box-shadow: inset 0px 0px 0px transparent !important;
  }
  &:hover::before {
    border-bottom: 0px !important;
  }
}

.nav-icon {
  width: 25px;
  height: 25px;
  transition: 0.2s;
  &:hover {
    filter: brightness(1%) saturate(100%) invert(82%) sepia(20%) saturate(4891%) hue-rotate(312deg) brightness(120%)
      contrast(91%);
  }
}

.social-link {
  display: inline-block;
  font-size: 1.75rem;
  margin-right: 0.5rem;
  color: $tds-grey-300;
  &:hover,
  &:focus {
    color: $primary !important;
    text-decoration: none;
  }
}

@media (max-width: 991px) {
  #primarynav {
    .navbar-nav {
      align-items: center;
      height: 300px;
      justify-content: space-around;
    }
  }
}
