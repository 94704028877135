// Home page animations

// #shield-sreen-blue {
//   background-color: transparent !important;
//   box-shadow: inset 0px 00px 0px $tds-blue-300;
//   transition: 0.8s;
// }

// .shield-sreen-blue-fill {
//   box-shadow: inset 0px -700px 0px $tds-blue-300 !important;
// }

// #human-values-green {
//   background-color: transparent !important;
//   box-shadow: inset 0px 00px 0px $primary;
//   transition: 0.8s;
// }

// .human-values-green-fill {
//   box-shadow: inset 0px -700px 0px $primary !important;
// }

// .animate {
//   opacity: 0;
// }

// .fade-up-large {
//   opacity: 0;
//   transform: scale(0.9);
//   transform: translatey(50px);
//   animation: fade-up-lg 0.8s;
//   animation-fill-mode: forwards;
//   &:nth-child(1) {
//     animation-delay: 0.5s !important;
//   }
//   &:nth-child(2) {
//     animation-delay: 1s !important;
//   }
//   &:nth-child(3) {
//     animation-delay: 1.5s !important;
//   }
//   &:nth-child(4) {
//     animation-delay: 2s !important;
//   }
//   &:nth-child(5) {
//     animation-delay: 2.5s !important;
//   }
//   &:nth-child(6) {
//     animation-delay: 2.2s !important;
//   }
// }

// @keyframes fade-up-lg {
//   from {
//     opacity: 0;
//     transform: scale(0.9);
//     transform: translatey(50px);
//   }
//   to {
//     opacity: 1;
//     transform: scale(1);
//     transform: translatey(0px);
//   }
// }

.animate-text-fade-up,
.animate-text-fade-up-delay,
.animate-text-fade-up-delay-long,
.animate-opacity-fade,
.animate-benefits-image {
  opacity: 0;
  // transition: 1s;
  transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
//---------------------------------------
.text-fade-up {
  transform: translatey(0px);
  animation: textFadeUp 1.2s;
  animation-fill-mode: forwards;
  &:nth-child(1) {
    animation-delay: 0s !important;
  }
  &:nth-child(2) {
    animation-delay: 0.2s !important;
  }
  &:nth-child(3) {
    animation-delay: 0.4s !important;
  }
  &:nth-child(4) {
    animation-delay: 0.5s !important;
  }
  &:nth-child(5) {
    animation-delay: 0.6s !important;
  }
  &:nth-child(6) {
    animation-delay: 0.68s !important;
  }
  &:nth-child(7) {
    animation-delay: 0.71s !important;
  }
}
.text-fade-up-delay {
  animation: textFadeUp 0.8s;
  animation-fill-mode: forwards;
  &:nth-child(1) {
    animation-delay: 0.8s !important;
  }
  &:nth-child(2) {
    animation-delay: 1s !important;
  }
  &:nth-child(3) {
    animation-delay: 1.2s !important;
  }
  &:nth-child(4) {
    animation-delay: 1.4s !important;
  }
  &:nth-child(5) {
    animation-delay: 1.4s !important;
  }
}
.text-fade-up-delay-long {
  animation: textFadeUp 0.8s;
  animation-fill-mode: forwards;
  &:nth-child(1) {
    animation-delay: 1.6s !important;
  }
  &:nth-child(2) {
    animation-delay: 1.8s !important;
  }
  &:nth-child(3) {
    animation-delay: 2s !important;
  }
  &:nth-child(4) {
    animation-delay: 2.2s !important;
  }
  &:nth-child(5) {
    animation-delay: 2.3s !important;
  }
}
@keyframes textFadeUp {
  from {
    opacity: 0;
    transform: translatey(50px);
  }
  to {
    opacity: 1;
    transform: translatey(0px);
  }
}
//---------------------------------------
.animate-section-fadein {
  opacity: 0;
  transition: 0.5s;
  transform: translatey(0%);
}
.fade-in {
  opacity: 1;
  // transform: translatey(-150px);
}
//---------------------------------------
.animate-section-fadeout {
  // opacity: 1;
  transition: 0.5s;
}
.fade-out {
  opacity: 0;
}
//---------------------------------------
.overview-vision-title {
  position: relative;
}
.fixed {
  position: sticky;
}
//---------------------------------------
.card-fade {
  opacity: 0;
  transition: 0.5s;
}
.fade-out {
  opacity: 1;
}

//---------------------------------------
.opacity-fade-in {
  animation: opacityFadeIn 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-fill-mode: forwards;
  animation-delay: 0.8s !important;
}
//---------------------------------------
.animate-blue-bg-fill {
  animation: blueBgFill 0.8s cubic-bezier(0.5, 1, 0.89, 1);
  animation-fill-mode: forwards;
  background-color: transparent !important;
}
@keyframes blueBgFill {
  from {
    background-color: transparent !important;
    box-shadow: inset 0px 0px 0px $tds-blue-300;
    opacity: 0;
  }
  to {
    box-shadow: inset 0px -700px 0px $tds-blue-300;
    opacity: 1;
  }
}
//---------------------------------------
// .animate-green-bg {
//   transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
// }

.animate-green-bg-fill {
  animation: greenBgFill 1.2s cubic-bezier(0.5, 1, 0.89, 1);
  animation-fill-mode: forwards;
  background-color: transparent !important;
  animation-delay: 1.4s !important;
}
@keyframes greenBgFill {
  from {
    background-color: transparent !important;
    box-shadow: inset 0px 0px 0px $primary;
    opacity: 0;
  }
  to {
    box-shadow: inset 0px -650px 0px $primary;
    opacity: 1;
  }
}
//---------------------------------------
.benefits-image-fade-up {
  animation: benefitsImageFadeUp 1.2s;
  animation-fill-mode: forwards;
  animation-delay: 0.4s;
}
//---------------------------------------

//---------------------------------------
.animate-fadein-fadeout {
  opacity: 0;
  transition: all 0.4s;
}

.fadeinFadeout {
  animation: textFadeInFadeOut;
  animation-fill-mode: forwards;
  opacity: 1;

  &:nth-child(1) {
    animation-delay: 0s !important;
  }
  &:nth-child(2) {
    animation-delay: 0.2s !important;
  }
  &:nth-child(3) {
    animation-delay: 0.4s !important;
  }
  &:nth-child(4) {
    animation-delay: 0.5s !important;
  }
  &:nth-child(5) {
    animation-delay: 0.6s !important;
  }
}
@keyframes textFadeInFadeOut {
  from {
    transform: translatey(50px);
  }
  to {
    transform: translatey(0px);
  }
}
//---------------------------------------
@keyframes opacityFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes benefitsImageFadeUp {
  from {
    opacity: 0;
    top: 120%;
  }
  to {
    opacity: 1;
    top: 50%;
  }
}

//---------------------------------------
.draw {
  animation-name: draw;
  animation-duration: 1.5s;
  animation-fill-mode: forwards; // Stay on the last frame
  animation-iteration-count: 1; // Run only once
  animation-timing-function: linear;
  animation-delay: 0.5s;
  stroke-dashoffset: 0;
}
@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
//---------------------------------------
.intro {
  .shield {
    background-image: url("/img/home/waves.svg");
    // background-size: auto 100%;
    background-size: cover;
    background-repeat: repeat-x;
    background-position: 0px 0px;
    animation-name: waveScroll;
    animation-duration: 100s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}
@keyframes waveScroll {
  // 0% {
  //   left: 0px;
  // }
  // 50% {
  //   left: 800px;
  // }
  100% {
    background-position: 1000px 0px;
  }
}
//---------------------------------------
