#caseStudyButton {
  display: inline-block;
  font-size: 1.5rem;
  width: 100%;
  padding: 25px;
  text-transform: uppercase;
  background-color: $tds-green-200;
  color: $tds-blue-300;
  box-shadow: inset 0px 0px 0px $tds-blue-300;
  &:focus,
  &:hover {
    text-decoration: none;
    color: $white;
    box-shadow: inset 0px -80px 0px $tds-blue-300;
  }
}

.case-study-layout {
  h1,
  .h1 {
    font-family: $font-family-sans-serif;
    text-transform: uppercase;
    color: $tds-green-200;
    font-size: 1.75rem;
  }
  h2,
  .h2 {
    font-family: $font-family-serif;
    font-weight: 600;
    margin-top: 0.5rem;
  }
  h3,
  .h3 {
    font-family: $font-family-serif;
    font-weight: 400;
    margin-top: 0.5rem;
  }
  .process-method {
    font-family: $font-family-sans-serif;
    text-transform: uppercase;
    color: $tds-green-200;
  }
  p {
    opacity: 0.9;
    margin-bottom: 2rem;
  }
  a {
    color: $tds-blue-200;
    text-decoration: underline;
    opacity: 0.9;
    transition: 0.2s;
    &:focus,
    &:hover {
      opacity: 1;
      color: $tds-orange-100;
    }
  }
  ol,
  ul {
    padding: 15px;
    font-family: $font-family-sans-serif !important;
    font-size: 1rem;
    margin: 25px;
    li {
      margin-bottom: 0.75rem;
    }
  }
}

blockquote {
  margin: 25px;
  p {
    font-family: $font-family-sans-serif !important;
    font-size: 1rem;
  }
}

.standard-mapping {
  display: flex;
  border-top: 1px solid $dark;
  color: $tds-grey-300;
  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
  .section {
    display: flex;
    flex-flow: column;
    width: 25%;
    border-right: 1px solid $dark;
    &:first-of-type {
      border-left: 1px solid $dark;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      border-left: 1px solid $dark;
    }
  }
  .area,
  .cell {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
    height: 120px;
    border-bottom: 1px solid $dark;
    @include media-breakpoint-down(sm) {
      padding: 1rem;
      height: auto;
      &.blank {
        display: none;
      }
    }
    &.active {
      background-color: $primary;
      color: $white;
    }
  }

  .area {
    background-color: $dark;
    color: $white;
    text-transform: uppercase;
    font-size: 1.25rem;
    @include media-breakpoint-down(sm) {
      background-color: $dark !important;
    }
  }
}
