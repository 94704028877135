.overview-page {
  section {
    padding: 40px 0;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      padding: 10% 0;
    }
  }
}

#illustration-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  #overview-illustration {
    max-width: 100%;
    fill: transparent !important;
    stroke: #fff;
    stroke-width: 1;
    stroke-miterlimit: 10;
    stroke-dasharray: 1100px;
    stroke-dashoffset: 1100px;
    stroke-linecap: round;
  }
}

.animated-wrapper {
  position: relative;
  max-width: 450px;
  min-width: 50%;
  min-height: 150px;
  .animated-bg {
    padding: 25px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 450px;
  }
  .animated-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 250px;
  }
  #security-bg {
    fill: transparent !important;
    stroke: #000000;
    stroke-width: 3.855;
    stroke-miterlimit: 10;
    stroke-dasharray: 1450px;
    stroke-dashoffset: 1450px;
    stroke-linecap: round;
  }
  #privacy-bg {
    // fill: transparent !important;
    // stroke: #000000;
    stroke-width: 3.5;
    // stroke-miterlimit: 10;
    // stroke-dasharray: 1300px;
    // stroke-dashoffset: 1300px;
    fill: none;
    stroke: #000000;
    // stroke-width: 2.9752;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-dasharray: 2400px;
    stroke-dashoffset: 2400px;
  }
  #ownership-bg {
    fill: transparent !important;
    stroke: #000000;
    stroke-width: 2 !important;
    stroke-miterlimit: 10;
    stroke-dasharray: 1300px;
    stroke-dashoffset: 1300px;
    stroke-linecap: round;
  }
  #governance-bg {
    fill: none;
    stroke: #000000;
    stroke-width: 2.9762;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-dasharray: 300px;
    stroke-dashoffset: 300px;
  }
}
#overview-vision {
  overflow: visible;
}

@media (max-width: 992px) {
  .animated-wrapper {
    .animated-bg {
      max-width: 400px;
    }
    .animated-icon {
    }
  }
}
@media (max-width: 768px) {
  .animated-wrapper {
    .animated-bg {
      max-width: 250px;
    }
    .animated-icon {
      height: 150px;
    }
  }
}

.overview-row {
  height: 500px;
}

#how-to {
  p {
    small {
      font-size: 1rem;
    }
  }
}
