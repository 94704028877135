.home-page {
  section {
    padding: 0;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
}

.home-grid-container {
  display: grid;
  width: 100vw;
  grid-template-columns: 1fr 1fr;
  // grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-areas: "intro intro" "get-involved-wrapper get-involved-wrapper" "featured-use-wrapper featured-use-wrapper" "start-now start-now";
  // color: $white;
}

.intro {
  display: grid;
  width: 100vw;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  word-wrap: break-word;
  grid-area: intro;
  grid-template-areas: "shield top" "shield bot";
  .shield {
    grid-area: shield;
    height: 100%;
    background-color: #002e34;

    .container {
      background-color: $tds-blue-300;
      padding: 90px;
    }
  }
  .top {
    grid-area: top;
    min-height: 50%;
  }
  .bot {
    grid-area: bot;
    min-height: 50%;
    // background-color: $tds-green-200;
  }
}

#digital-standard-badge {
  height: 250px;
}

.get-involved-wrapper {
  display: grid;
  width: 100vw;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-area: get-involved-wrapper;
  grid-template-areas: "image-one get-involved";
  .image-one {
    grid-area: image-one;
    background-image: url("/img/home/image-one.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .get-involved {
    grid-area: get-involved;
    background-color: $tds-blue-200;
    background-image: url("/img/home/get-involved-bg.jpg");
    p {
      color: $white !important;
    }
  }
}
.featured-use-wrapper {
  display: grid;
  width: 100vw;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-area: featured-use-wrapper;
  grid-template-areas: "featured-use featured-image";
  .featured-use {
    grid-area: featured-use;
  }
  .featured-image {
    grid-area: featured-image;
  }
}
.start-now {
  display: grid;
  width: 100vw;
  // height: 540px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-area: start-now;
  background-image: url(/img/home/start-now-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media screen and (max-width: 992px) {
  .home-grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 600px 600px 540px;
    grid-template-areas: "intro" "get-involved-wrapper" "featured-use-wrapper" "start-now";
  }
  .intro {
    grid-template-columns: 1fr, 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "shield shield" "top bot";
  }
}

@media screen and (max-width: 768px) {
  .home-grid-container {
    grid-template-rows: 1800px 900px 850px 540px;
    grid-template-areas: "intro" "get-involved-wrapper" "featured-use-wrapper" "start-now";
  }
  .intro {
    grid-template-columns: 1fr;
    grid-template-rows: 700px 500px 600px;
    grid-template-areas: "shield" "top" "bot";
    .shield {
      .container {
        padding: 35px;
        margin: 0 auto;
        height: 95%;
        min-width: 90vw;
      }
    }
  }
  .get-involved-wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 250px 650px;
    grid-template-areas: "image-one" "get-involved";
  }
  .featured-use-wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 550px 300px;
    grid-template-areas: "featured-use" "featured-image";
  }
}

.human-value {
  color: $tds-green-200;
  background-color: $white;
  font-weight: 600;
  border: 2px solid $white;
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: 1.375rem;
  .human-value-icon {
    background-size: cover;
    background-position: center center;
    height: 63px;
    margin-right: 20px;
    width: 75px;
  }
  #security-icon {
    background-image: url("/img/home/icons/security.svg");
  }
  #privacy-icon {
    background-image: url("/img/home/icons/privacy.svg");
  }
  #ownership-icon {
    background-image: url("/img/home/icons/ownership.svg");
  }
  #governance-icon {
    background-image: url("/img/home/icons/governance.svg");
  }
}
