.half-grid {
  // max-width: 520px;
  padding-bottom: 56%;
  .container {
    max-width: 600px;
  }
  // @media (max-width: 1200px) {
  //   max-width: 430px;
  // }
  // @media (max-width: $screen-md-min) {
  // }
}
.grid-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
  max-width: 100vw;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 768px) {
    padding: 15px;
  }
}
.green {
  color: $tds-green-200 !important;
}
.white {
  color: $white !important;
}
.black {
  color: $black !important;
}
.blue {
  color: $tds-blue-200 !important;
}
.orange {
  color: $tds-orange-100 !important;
}
.gray {
  color: $gray-500 !important;
}
.serif {
  font-family: serif !important;
}
.paragraph {
  font-size: 1.3rem;
  line-height: 1.8rem;
  font-weight: 400;
}

.small-text {
  font-size: 0.875rem !important;
  line-height: 1.4rem;
}
.large-text {
  font-size: 2.3rem;
  font-weight: 600;
}
.text-thin {
  font-weight: 300 !important;
}

.value-icon {
  margin: 20px 0;
  height: 40px;
}

.casestudy-sort {
  text-transform: uppercase;
  color: $tds-blue-300;
  font-weight: 600;
  padding: 8px 3px;

  &:focus,
  &:hover {
    color: $tds-blue-300;
    text-decoration: none;
    border-bottom: 2px solid $tds-blue-300;
  }
  &:active {
  }
}

.white-button {
  color: $white;
  border: 1px solid $white;
  box-shadow: inset 0px 0px 0px $white;
  &:hover,
  &:focus {
    color: $white;
    box-shadow: inset 0px -80px 0px $white;
    color: $tds-orange-100;
  }
}
.green-button {
  color: $tds-green-100;
  border: 1px solid $tds-green-100;
  box-shadow: inset 0px 0px 0px $tds-green-100;
  &:hover,
  &:focus {
    box-shadow: inset 0px -80px 0px $tds-green-100;
    color: $tds-blue-300;
  }
}
.blue-button {
  color: $white;
  border: 1px solid $tds-blue-300;
  background-color: $tds-blue-300;
  box-shadow: inset 0px 0px 0px $tds-blue-100;
  &:hover,
  &:focus {
    box-shadow: inset 0px -80px 0px $tds-blue-100;
    // color: $tds-blue-300;
    background-color: $tds-blue-200 !important;
    color: $white !important;
  }
  &:active {
    box-shadow: inset 0px -80px 0px $tds-blue-200;
    background-color: $tds-blue-200 !important;
  }
}
.overflow-visible {
  overflow: visible !important;
}

.btn-full-width {
  width: 100%;
  background-color: $tds-orange-100;
  font-weight: 400 !important;
  box-shadow: inset 0px 0px 0px $tds-blue-300;
  border: 0px;
  &:hover,
  &:focus {
    box-shadow: inset 0px -80px 0px $tds-blue-300;
    color: $white;
  }
}

// Background utlities
.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  &.bg-contain {
    background-size: contain;
  }
  &.aspect-1x1 {
    padding-top: 100%;
    video {
      left: -25%;
      height: 100%;
      max-width: none;
      width: auto;
      object-fit: cover;
    }
    video[poster] {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  &.aspect-2x1 {
    padding-top: 50%;
  }
  &.aspect-3x1 {
    padding-top: 33%;
  }
  &.aspect-4x3 {
    padding-top: 75%;
  }
  &.aspect-5x7 {
    padding-top: 120%;
  }
  &.aspect-16x9 {
    padding-top: 56.25%;
  }
}

.bg-secondary {
  background-color: $tds-orange-100;
}

.bg-grey {
  background-color: $gray-100;
}

// Color utilities
.text-mid-grey {
  color: $tds-grey-300 !important;
}

// Text utilities
.text-large {
  font-size: 2.3rem;
  font-weight: 600;
}
.text-medium {
  font-size: 1.25rem;
  font-weight: 600;
}

.text-small {
  font-size: 0.875rem;
  line-height: 1.4rem;
}

.text-serif {
  font-family: $font-family-serif !important;
}

.text-sans-serif {
  font-family: $font-family-sans-serif !important;
}

.text-nocase {
  text-transform: none !important;
}

// Layout Utilities
.content {
  max-width: 600px;
  margin: auto;
  padding: 2rem;
}

.overflow-hidden {
  overflow: hidden !important;
}

// Background Utilities
.bg-status {
  display: inline-block;
  min-height: 13px;
  min-width: 13px;
  border-radius: 100%;
  background-color: $dark;
  .bg-status-lg {
    height: 28px;
    width: 28px;
  }
}
.bg-fill {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-full {
  min-height: calc(100vh - 500px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-loading {
  position: fixed;
  top: 56px;
  height: 100vh;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  pointer-events: none;
  background-color: $white;
  z-index: 9999;
  @include transition(1s);
  &.loaded {
    opacity: 0;
  }
}

.partner-logo {
  max-height: 40px;
  max-width: 80vw;
  transition: 0.4s;
  cursor: pointer;
  filter: grayscale(100%);
  &:hover {
    filter: grayscale(0%);
  }
}

.standards-button {
  .human-value {
    box-shadow: inset 0px 0px 0px $tds-blue-300;
    transition: 0.4s;
    &:hover {
      box-shadow: inset 0px -80px 0px $tds-blue-300;
      border: 2px solid $tds-blue-300;
      text-decoration: none;
      color: $white;
    }
  }
  &:hover {
    text-decoration: none;
  }
}

// .bg-half {
//   min-height: 50vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .bg-third {
//   min-height: 33vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
