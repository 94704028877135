// TODO: Enter all Bootstrap overrides here...
.btn {
  text-transform: uppercase;
  font-family: $font-family-base;
  font-weight: 600;
  font-size: 1rem;
  padding: 16px 33px;
  border-radius: 0;
  display: inline-block;
  text-align: center;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  // display: inline-block;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.btn-outline-primary {
  box-shadow: inset 0px 0px 0px $primary;
  &:hover,
  &:focus {
    color: $white;
    box-shadow: inset 0px -80px 0px $primary;
  }
}

.btn-outline-secondary {
  box-shadow: inset 0px 0px 0px $secondary;
  &:hover,
  &:focus {
    color: $white;
    box-shadow: inset 0px -80px 0px $secondary;
  }
}

.btn-outline-dark {
  background-color: transparent;
  box-shadow: inset 0px 0px 0px $dark;
  &:hover,
  &:focus {
    color: $white;
    // background-color: $dark;
    box-shadow: inset 0px -80px 0px $dark;
  }
}

.btn-outline-white {
  box-shadow: inset 0px 0px 0px $white;
  &:hover,
  &:focus {
    color: $dark;
    box-shadow: inset 0px -80px 0px $white;
  }
}

.btn-outline-yellow {
  box-shadow: inset 0px 0px 0px $yellow;
  &:hover,
  &:focus {
    color: $dark;
    box-shadow: inset 0px -80px 0px $yellow;
  }
}

.border-right {
  @include media-breakpoint-down(md) {
    border-right: 0 !important;
    border-bottom: 1px solid $tds-grey-300;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
.border-left {
  @include media-breakpoint-down(md) {
    border-left: 0 !important;
    border-top: 1px solid $tds-grey-300;
    padding-top: 0.5rem;
    margin-top: 0.5rem;
  }
}

.container {
  @include media-breakpoint-down(md) {
    max-width: 95%;
    width: auto;
  }
  @include media-breakpoint-up(xxl) {
    max-width: 1400px;
  }
}

.container-fluid {
  padding: 0;
  .row {
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(md) {
      min-height: 0;
    }
  }
}

// Carousels
.carousel-control-prev,
.carousel-control-next {
  position: relative;
  color: inherit;
  display: block;
  width: 20px;
  background-color: transparent;
  font-size: 2rem;
  &:hover {
    color: $dark;
  }
}

// Forms
.form-control {
  border-radius: 0;
  border-color: $tds-grey-300;
}
.form-group {
  label {
    text-transform: uppercase;
    font-size: 0.875rem;
    letter-spacing: 1px;
    color: $tds-grey-300;
  }
}
