html {
  scroll-behavior: smooth;
  scroll-padding-top: 135px;
}
body {
  color: $body-color;
}

section {
  padding: 40px 0;
  overflow: visible;
  @include media-breakpoint-up(md) {
    padding: 5% 0;
  }
}

a,
button {
  &:focus {
    outline: none;
  }
}

::selection {
  background: $tds-green-100;
}
