.standard-layout {
  opacity: 1;
  @include transition(0.5s);
  #pageContent {
    min-height: calc(100vh - 100px);
  }
  .sidebar {
    position: fixed;
    left: -275px;
    top: 112px;
    padding: 0 1.75rem;
    width: 300px;
    min-width: 300px;
    min-height: calc(100vh - 112px);
    height: 100%;
    background-color: $tds-blue-100;
    color: $white;
    overflow-y: scroll;
    @include transition(0.5s);
    &.active {
      left: 0;
    }
    @include media-breakpoint-up(lg) {
      // position: relative;
      left: 0;
      // padding-top: 1.5rem;
    }
    #sectionNav {
      margin-top: 1.5rem;
      padding-bottom: 100px;
    }
    .sidebar-toggle {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      top: 0.45rem;
      right: 0.1rem;
      height: 24px;
      width: 24px;
      background-color: transparent;
      border: 0;
      font-size: 1.5rem;
      color: $light;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
      @include media-breakpoint-up(lg) {
        display: none;
        pointer-events: none;
      }
    }
    h4.nav-link {
      color: $white;
      font-family: $font-family-serif;
      margin-top: 1.125rem;
      margin-bottom: 0px;
      opacity: 0.5;
      font-weight: 600;
      font-size: 1.1rem !important;
      text-transform: none;
      padding-left: 0;
      &::before {
        height: 0px;
        width: 0px;
      }
      .active {
        opacity: 1;
      }
    }
    i.caret {
      transform: scale(1.6);
      opacity: 0.5;
      margin-left: 0.5rem;
      display: block;
    }

    .collapse-toggle[aria-expanded="true"] {
      h4 {
        opacity: 1;
      }
      > .caret {
        opacity: 1;
        transform: scale(1.6) rotate(90deg);
      }
    }
    .collapse-toggle {
      justify-content: space-between;
      cursor: pointer;
      &:hover {
        h4,
        i {
          opacity: 1;
        }
      }
    }

    .nav-link {
      position: relative;
      padding: 0.3rem;
      padding-left: 1rem;
      text-transform: uppercase;
      font-size: 0.8rem;
      color: $white;
      letter-spacing: 1px;
      opacity: 0.5;
      @include transition();
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 12px;
        left: 0;
        height: 7px;
        width: 7px;
        background-color: $mid;
        border-radius: 100%;
        @include transition();
      }
      &:hover,
      &.active {
        color: $white;
        font-weight: 600;
        opacity: 1;
        &::before {
          background-color: $white;
        }
      }
    }
  }
  .standard-content {
    opacity: 1;
    min-height: calc(100vh - 610px);
    padding-top: 55px;
    @include transition(1s);
    h1 {
      font-size: 2rem;
    }
    h2,
    h3 {
      font-size: 1rem;
    }
    @include media-breakpoint-up(lg) {
      margin-left: 300px;
    }
  }
  &.loading {
    opacity: 0;
  }
}

#standardnav {
  position: fixed;
  width: 100%;
  height: 56px;
  top: 56px;
  padding-left: 25px;
  background-color: $tds-blue-300;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 9;
  // box-shadow: 1px 1px 2px rgba($black, 0.25);
  .dropdown {
    margin-left: -25px;
    .dropdown-menu {
      background-color: $gray-100;
      border-radius: 0px;
      margin-top: 6px;
      border: 0px;
      z-index: 9999;
      a:hover,
      a:active,
      a:focus {
        color: $secondary;
        background-color: transparent;
      }
    }
    .btn {
      padding: 15px;
      text-transform: none;
      border-color: transparent;
      background-color: $gray-600;
      color: $white;
      &:active,
      &:hover,
      &:focus {
        background-color: $secondary;
        outline: 0;
        box-shadow: none;
      }
    }
  }
  .nav {
    margin-right: auto;
  }
  .nav-link {
    padding: 0.5rem 0.75rem;
    color: $gray-600;
    border: 0;
    font-size: 0.85rem;
    font-family: sofia-pro, sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    &.active,
    &:hover {
      color: $white;
    }
  }
  #downloadCTA {
    font-size: 0.85rem;
    padding-right: 30px;
    &.active,
    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
}

.bg-icon {
  content: none;
  min-height: 25px;
  min-width: 25px;
  border-radius: 50%;
}

#standard-landing-wrapper {
  h1 {
    font-size: 1.5rem;
    line-height: 1.2;
    font-weight: 600;
  }
  h2 {
    color: $gray-600;
  }
  .aside {
    p {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
}

.standard-wrapper {
  margin-bottom: 50px;
  padding: 0 15px;
  .criteria-wrapper {
    padding: 20px;
    background-color: $tds-blue-300;
    color: $white;
    .sticky-top {
      top: 125px;
    }
    h3 {
      font-weight: 600;
      color: $white !important;
    }
  }
  .indicator-wrapper {
    background-color: $gray-200;
    padding: 20px;
    hr {
      border-top: 2px solid $gray-600;
    }

    h4 {
      font-size: 1rem;
      color: $tds-blue-300 !important;
      font-weight: 600;
    }
    li {
      font-size: 1rem;
      font-family: $font-family-serif;
      list-style-type: square;
    }
    .procedure-wrapper {
      background-color: $white;
      padding: 15px;
      border-left: 5px solid $gray-600;
      white-space: pre-wrap;
      p {
        font-family: $font-family-sans-serif;
        font-size: 1rem;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: -5px;
        margin-top: 10px;
        margin-left: -15px;
      }
      ul {
        padding: 0;
        margin-bottom: 0;
      }
      li {
        font-size: 1rem;
        font-family: $font-family-serif;
        padding: 10px 0;
        list-style-type: none;
        &::before {
          transform: rotate(45deg);
        }
      }
    }
  }
}
