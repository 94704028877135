// Preloader behavior for cognito form
.preloader {
  display: none;
  width: 100%;
  text-align: center;
  img {
    width: 50px;
  }
}

// Override the default invisibility if the preloader doesn't have a form after it.
.preloader:last-child {
  display: block !important;
}

.c-forms-form-main {
  input {
    border: 1px solid $tds-grey-300;
    height: 40px;
    position: relative;
  }
  select {
    border: 1px solid $tds-grey-300;
    height: 40px;
    position: relative;
  }
  #c-3-2 {
    border: 1px solid $tds-grey-300 !important;
    height: 80px !important;
    position: relative;
  }
  label {
    text-transform: uppercase;
    font-size: 0.875rem;
    letter-spacing: 1px;
    color: $tds-grey-300;
    position: absolute;
    bottom: -22px;
  }

  .c-field {
    position: relative;
  }
}
.c-action {
  margin-top: 25px !important;
  button {
    color: $secondary;
    background-color: $white;
    border: 1px solid $secondary;
    box-shadow: inset 0px 0px 0px $secondary;
    &:hover,
    &:focus {
      color: $white;
      box-shadow: inset 0px -80px 0px $secondary;
      border: 1px solid $secondary;
    }
  }
}
.c-label::after {
  display: none;
}

.btn-primary {
  background-color: $white !important;
  border-color: $secondary !important;
}

/* Set the preloader to be invisible by default */
.preloader {
  display: none;
  position: relative;
  img {
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(50%, 50%);
  }
}

/* Override the default invisibility if the preloader doesn't have a form after it. */
.preloader:last-child {
  display: block;
}

/* Create a spinning animation and name it "spin" */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.c-editor,
select {
  background-color: $white !important;
}

/*
  Apply "spin" animation to the image inside the preloader.
   -  animation-duration is set to "3s" and so each
      rotation will last 3 seconds.
   -  animation-timing-function is set to "linear"
      and so the speed of rotation is constant.
   -  animation-iteration-count is set to "infinite"
      which means what it says.
*/
.preloader img {
  // &:nth-child(1) {
  //   width: 60px;
  //   height: 60px;
  // }
  // &:nth-child(1) {
  //   width: 30px;
  //   height: 30px;
  // }
  display: block;
  animation-name: spin;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
