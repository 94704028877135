@font-face {
  font-family: "faidherbev";
  src: url("../fonts/Faidherbev0.9-NmQtBld.woff") format("woff");
  src: url("../fonts/Faidherbev0.9-NmReg.woff") format("woff");
}

body {
  font-size: 16px;
  line-height: 1.25rem;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
p,
a {
  @include transition();
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  color: inherit;
}

h1,
.h1 {
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-weight: 600;
}

h2,
.h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

h3,
.h3 {
  font-size: 1.5rem;
  font-weight: 400;
}

h4,
.h4 {
  font-size: 1.2rem;
  font-weight: 400;
}

#pageContent {
  p,
  .p {
    color: inherit;
    font-family: $font-family-serif;
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 400;
    small {
      font-size: 0.875rem;
      line-height: 1.4rem;
    }
  }
}
