@import "settings";
@import "vendor/bootstrap/scss/bootstrap"; // Copied from node_modules and committed to source to allow Bootstrap to compile during build

// Global
@import "global/mixins";
@import "global/utilities";
@import "global/base";
@import "global/layout";
@import "global/typography";
@import "global/animations";
@import "global/navigation";

// Pages
@import "pages/home";
@import "pages/contribute";
@import "pages/overview";
@import "pages/standard";
@import "pages/case-study";

// Vendor Specific
@import "vendor/bootstrap_mods";
@import "vendor/cognito";
